import { useState, useEffect } from 'react';

export const isApplePayAvailable = (): boolean => {
  /**
   * window.ApplePaySession is only available on supported devices.
   * This is the recommended way to check for Apple Pay availability.
   * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
   */

  return Boolean(window.ApplePaySession);
};

export const useApplePayAvailability = () => {
  const [isSupported, setSupported] = useState(false);

  useEffect(() => {
    if (isApplePayAvailable()) {
      setSupported(true);
    }
  }, []);

  return isSupported;
};
