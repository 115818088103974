/**
 * This method replaces variables inside translation strings
 * based on the provided replacement map
 * If the variable is not provided as a replacement the original placeholder value is kept
 * @param text 'This is a big sentence where this should be {{ replaced }}
 * @param replacements { replaced: 'nice' }
 * @returns string - 'This is a big sentence where this should be nice
 */
export const parseTranslation = (
  text: string,
  replacements: Record<string, string> = {},
): string =>
  text.replace(
    /\{\{(.*?)\}\}/g, // {{ variable }}
    (placeholder, capturedValue: string) => {
      const variableName = capturedValue.trim();
      return !replacements[variableName]
        ? placeholder // default to placeholder if not found
        : placeholder.replace(placeholder, replacements[variableName]);
    },
  );
