import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NotificationInline } from '@sumup-oss/circuit-ui';
import { Grid } from '@sumup-oss/circuit-ui/legacy';
import {
  Component as ReactComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type ComponentType,
  type FC,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

export interface NotificationContextValue {
  isNotificationVisible: boolean;
  setNotification: (value: boolean) => void;
  displayNotification: (options: NotificationOptions) => void;
}

export interface Props {
  message: string | ReactNode;
  variant: 'info' | 'alert' | 'confirm' | 'notify';
  sendToTop?: boolean;
  closeButtonLabel?: string;
}

interface NotificationOptions extends Props {
  title?: string;
}

export const NOTIFICATION_DEFAULT_DURATION_MS = 10_000;

const LEGACY_VARIANTS = {
  confirm: 'success',
  notify: 'warning',
  alert: 'danger',
  info: 'info',
} as const;

const StyledDiv = styled('div')<{ sendToTop?: boolean }>(
  ({ theme, sendToTop }) => css`
    position: fixed;
    top: ${sendToTop ? 'var(--cui-spacings-mega)' : '10px'};
    width: 100%;
    z-index: var(--cui-z-index-absolute) !important;
    left: 0;

    ${theme.mq.untilMega} {
      left: 50%;
      transform: translateX(-50%);
    }

    ${theme.mq.giga} {
      top: ${sendToTop ? 'var(--cui-spacings-giga)' : '10px'};
    }
  `,
);

export const NotificationContext = createContext<NotificationContextValue>({
  isNotificationVisible: false,
  setNotification: () => {},
  displayNotification: () => {},
});

/**
 * @deprecated
 *
 * Use the `useNotificationToast` hook from Circuit UI instead.
 */
export const NotificationProvider: FC<PropsWithChildren<Props>> = ({
  children,
  variant,
  message,
  sendToTop = false,
  closeButtonLabel = 'Close',
}) => {
  const [isNotificationVisible, setNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] =
    useState<NotificationOptions>({
      variant,
      message,
      sendToTop,
      title: null,
    });

  const displayNotification = useCallback((options) => {
    setNotificationOptions(options);
    setNotification(true);
  }, []);

  useEffect(() => {
    let notificationTimer;

    if (isNotificationVisible) {
      notificationTimer = setTimeout(() => {
        setNotification(false);
      }, NOTIFICATION_DEFAULT_DURATION_MS);
    }
    return () => {
      clearTimeout(notificationTimer);
    };
  }, [isNotificationVisible]);

  const closeNotification = (): void => {
    setNotification(false);
  };

  const headline =
    notificationOptions.title &&
    ({
      label: notificationOptions.title,
      as: 'h4',
    } as const);

  const body = (notificationOptions.message ||
    notificationOptions.title) as string;

  return (
    <NotificationContext.Provider
      value={{ isNotificationVisible, setNotification, displayNotification }}
    >
      {children}
      <StyledDiv
        sendToTop={notificationOptions.sendToTop}
        data-testid="global-notification--error"
      >
        <Grid>
          <NotificationInline
            body={body}
            headline={headline}
            variant={LEGACY_VARIANTS[notificationOptions.variant]}
            isVisible={isNotificationVisible}
            onClose={closeNotification}
            closeButtonLabel={closeButtonLabel}
          />
        </Grid>
      </StyledDiv>
    </NotificationContext.Provider>
  );
};

/**
 * @deprecated
 *
 * Use the `useNotificationToast` hook from Circuit UI instead.
 */
export const withNotifications = <P extends object>(
  Component: ComponentType<P>,
): ComponentType<P> =>
  class WithNotification extends ReactComponent<P> {
    override render(): JSX.Element {
      return (
        <NotificationProvider message="" variant="confirm">
          <Component {...(this.props as P)} />
        </NotificationProvider>
      );
    }
  };

/**
 * @deprecated
 *
 * Use the `useNotificationToast` hook from Circuit UI instead.
 */
export const useNotifications = (): NotificationContextValue =>
  useContext<NotificationContextValue>(NotificationContext);
